<template>
	<div class="box">
		<div class="title_box flex">
			<div class="flex">
				<h3 :class="apply_for_invoice_title == '1' ? 'TitleHover' : 'h3'" @click="TitleShow('1')">
					申请发票
				</h3>
				<h3 :class="apply_for_invoice_title == '2' ? 'TitleHover' : 'h3'" @click="TitleShow('2')">
					我的开票资料
				</h3>
			</div>
			<div class="flex">
				<button @click="goAddInvoice" class="add_but">
					添加开票资料
				</button>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	components: {},
	computed: {
		...mapState(['apply_for_invoice_title'])
	},
	mounted() {
	},
	data() {
		return {
		}
	},
	methods: {
		TitleShow(item) {
			this.$store.state.apply_for_invoice_title = item
			if (this.apply_for_invoice_title == '1') {
				this.$router.push({
					path: '/user/apply_for_invoice/apply_for_invoice_data',
				})
			} else {
				this.$router.push({
					path: '/user/apply_for_invoice/apply_for_invoice_list',
				})
			}
		},
		// 跳转添加开票资源
		goAddInvoice() {
			this.$router.push({
				path: '/user/AddInvoice'
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/ApplyForInvoice';
</style>